<script>
import deliveryServices from '@/services/deliveryServices'
import { thenResolve } from '@/utils/index'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import { dateFormat } from '@/utils'
import loading from '@/utils/loading'
import { debounce } from 'lodash'

export default {
  data(){
    const that = this
    return {
      orderId: '',
      invoiceTitle: Object.freeze([
        {
          name: this.$t('购方税号'),
          key:'buyerTaxNo',
          value:''
        },
        {
          name: this.$t('购方名称'),
          key:'buyerName',
          value:''
        }
      ]),
      otherInfo: Object.freeze([
        {
          name: this.$t('车辆类型'),
          key:'carType',
          value:''
        },
        {
          name: this.$t('车辆识别代号'), 
          key:'vin',
          value:''
        },
        {
          name: this.$t('合格证号'),
          key:'vehCertificateNo',
          value:''
        },
        {
          name: this.$t('厂牌型号'),
          key:'brandModel',
          value:''
        },
        {
          name: this.$t('产地'),
          key:'vehOriginPlace',
          value:''
        },
        {
          name: this.$t('发动机号码'),
          key:'vehEngineNo',
          value:''
        },
        {
          name: this.$t('销售价格'),
          key:'salesPrice', 
          value:''
        },
      ]),
      invoiceResult: Object.freeze([
        {
          name: this.$t('发票号码'),
          key:'invoiceNo',
          value:''
        },
        {
          name: this.$t('开票日期'),
          key:'invoiceDate',
          value:''
        }
      ]),
      billInfo:{},
      taskCode:'',
      taskId:'',
      fileList: [],
      taskStatus: '',
      taskDetail: {},
      batchOrderList: [],
      batchOrderField: Object.freeze([
        {
          name: this.$t('车辆类型'),
          key:'carType',
          value:''
        },
        {
          name: this.$t('车辆识别代号'), 
          key:'vin',
          value:''
        },
        {
          name: this.$t('合格证号'),
          key:'vehCertificateNo',
          value:''
        },
        {
          name: this.$t('厂牌型号'),
          key:'brandModel',
          value:''
        },
        {
          name: this.$t('产地'),
          key:'vehOriginPlace',
          value:''
        },
      ]),
      selectIds: [],
      showSelectTime: false,
      invoiceArriveDate: '', // 发票到店时间
      invoiceNo: '', // 发票号码
      invoiceDate: '', // 开票日期
      maxDate: dayjs().toDate(),
      minDate: dayjs().subtract(1,'year').toDate(),
      timeKey: '',
      isDoubleCheck: false
    }
  },
  created() {
    const { id,taskCode,taskId,taskStatus,orderType } = this.$route.query
    this.taskId = taskId
    this.taskCode = taskCode
    this.taskStatus = taskStatus
    if (id){
      this.orderId = id
      this.billDetail()
      this.deliveryTaskDetail()
    }
    // 大客户,获取批量待申请的订单
    if (id && ['1','2','5'].includes(orderType)){
      this.getBatchOrder()
    }
  },
  methods:{
    // 任务详情
    deliveryTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.taskId
      }).then(res=>{
        const { invoiceUrl,invoiceArriveDate,invoiceNo,invoiceDate } = res?.executionJson || {}
        if (invoiceUrl){
          this.fileList = [{
            url: invoiceUrl,
            cosUrl: invoiceUrl,
            status: 'done'
          }]
        }
        if (invoiceArriveDate){
          this.invoiceArriveDate = invoiceArriveDate
        }
        if (invoiceNo&&invoiceUrl){
          this.invoiceNo = invoiceNo
        }
        if (invoiceDate&&invoiceUrl){
          this.invoiceDate = invoiceDate.split(' ')[0]
        }
      })
    },
    async getBatchOrder(){
      const res = await deliveryServices.getBatchOrder({ orderId: this.orderId, type:2 })
      // 默认选中全部
      this.selectIds = res.map(e=>e.id)
      this.batchOrderList = res
      // this.batchOrderList = [
      //   {
      //     id: 111,
      //     invoice: {
      //       carType:'大卡车 1',
      //       vin: '111',
      //       vehCertificateNo:'1111',
      //       brandModel: '奔驰',
      //       vehOriginPlace: '德国'
      //     }
      //   },
      //   {
      //     id: 112,
      //     invoice: {
      //       carType:'大卡车 12',
      //       vin: '1112',
      //       vehCertificateNo:'111122',
      //       brandModel: '宝马',
      //       vehOriginPlace: '英国'
      //     }
      //   },
      // ]
      console.log(res,'batchOrder')
    },
    onSelectTime(key){
      if (this.taskStatus==='4004003') return
      this.timeKey = key
      this.showSelectTime=true
    },
    onChange({ id }){
      if (this.selectIds.includes(id)){
        this.selectIds.splice(this.selectIds.indexOf(id),1)
      } else {
        this.selectIds.push(id)
      }
    },
    // 查询发票信息
    billDetail() {
      deliveryServices.billDetail({ orderId:this.orderId }).then(res=>{
        this.billInfo = res
        // console.log(this.billInfo,'999')
      })
    },
    // 上传前校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        file.status = 'done'
        file.message = ''
        file.url = content
        file.cosUrl = url
        this.fileList.push(file)
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
        this.fileList.push(file)
      })
    },
    beforeDelete(file){
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    dateConfirm(date){
      this[this.timeKey] = dateFormat(date,'YYYY-MM-DD')
      this.showSelectTime = false
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    // type 1: 申请开票  2:发票上传
    onSubmit : debounce(function (type) {
      const { taskCode,orderId,taskId } = this
      if (!taskCode && !orderId && !taskId) return
      if (type===2){
        if (!this.invoiceNo.trim()) return this.$toast('请输入发票号码')
        if (!this.invoiceDate) return this.$toast('请选择开票日期')
        if (!this.invoiceArriveDate) return this.$toast('请选择发票到店时间')
        if (!this.fileList.length || this.fileList[0].status==='failed') return this.$toast('请上传发票文件')
        this.isDoubleCheck = true
        return
      }
      if (type===1&&!this.selectIds.includes(this.orderId)){
        this.selectIds.push(this.orderId)
      }
      this.onConfirm(type)
    },500),
    onConfirm(type){
      loading.showLoading()
      const { taskCode,orderId,taskId } = this
      deliveryServices.deliveryExecution({
        executionJson:{
          taskCode,
          invoiceUrl: type===2 ? this.fileList[0].cosUrl : '',
          invoiceArriveDate: type===2 ? this.invoiceArriveDate : '',
          invoiceNo: type===2 ? this.invoiceNo : '',
          invoiceDate: type===2 ? `${this.invoiceDate} 00:00:00` : '',
        },
        orderIds: this.selectIds,
        taskStatus: type===2 ? '4004003':'4004004',
        id: taskId,
      }).then(thenResolve).then(res=>{
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      }).finally(()=>{
        loading.hideLoading()
      })
    }
  }
}
</script>
<template>
  <div class="page" :style="{'padding-bottom':taskStatus==='4004003'? '16px': '96px'}">
    <div v-if="billInfo.invoiceStatus==='202402'" class="common-block item">
      <div class="common-t">
        <div class="common-t-l">{{ $t('上传发票') }}</div>
      </div>
      <van-field
        v-model="invoiceNo"
        required
        :label="$t('发票号码')"
        type="number"
        maxlength="50"
        :placeholder="$t('请输入')"
        :disabled="taskStatus==='4004003'"
        input-align="right"
        :rules="[{required:true}]">
      </van-field>
      <van-field
        :value="invoiceDate"
        required
        :placeholder="$t('请选择')"
        :right-icon="taskStatus==='4004003'?'':'arrow'"
        readonly
        input-align="right"
        :rules="[{required:true}]"
        :disabled="taskStatus==='4004003'"
        @click="onSelectTime('invoiceDate')">
        <template #label>
          <div>
            <div>{{ $t('开票日期') }}</div>
            <div>{{ $t('(票面相关日期)') }}</div>
          </div>
        </template>
      </van-field>
      <van-field
        :value="invoiceArriveDate"
        required
        :label="$t('发票到店时间')"
        :placeholder="$t('请选择')"
        :right-icon="taskStatus==='4004003'?'':'arrow'"
        readonly
        input-align="right"
        :rules="[{required:true}]"
        :disabled="taskStatus==='4004003'"
        @click="onSelectTime('invoiceArriveDate')">
      </van-field>
      <van-field  readonly required>
        <template #input>
          <div style="color: rgba(100, 101, 102, 0.6);font-size: 12px;">{{ $t('上传发票时，请注意VIN号等信息一致') }}</div>
          <van-uploader
            class="upload"
            capture="camera"
            accept="image/*"
            :file-list="fileList"
            :preview-options="{closeable: true}"
            :before-read="beforeRead"
            :deletable="taskStatus!=='4004003'"
            :after-read="(file) => afterRead(file)"
            :before-delete="(file) => beforeDelete(file)"
            :max-count="1"/>
          </template>
      </van-field>
    </div>
    <div class="common-block item">
      <div class="common-t">
        <div class="common-t-l">{{ $t('发票抬头') }}</div>
      </div>
      <div class="info">
        <div v-for="(item,index) in invoiceTitle" :key="index" class="li">
          <div class="name">{{ item.name }}：</div>
          <div class="value">{{ billInfo[item.key]||''}}</div>
        </div>
      </div>
    </div>
    <div class="common-block item">
      <div class="common-t">
        <div class="common-t-l">{{ $t('发票信息') }}</div>
      </div>
      <div class="info">
        <div v-for="(item,index) in otherInfo" :key="index" class="li">
          <div class="name">{{ item.name }}：</div>
          <div v-if="item.key == 'salesPrice'" class="value">
            <span content="¥"></span>{{ formatRMB(billInfo[item.key]||'') }}
          </div>
          <div v-else class="value">{{ billInfo[item.key]||''}}</div>
        </div>
      </div>
    </div>
    <!-- 大客户，同一合同下待申请的订单 -->
    <div v-if="batchOrderList.length&&billInfo.invoiceStatus==='202410'&&taskStatus!=='4004004'">
      <div style="margin-bottom:10px">{{ $t('同一合同下还有以下订单可以申请开票，是否进行批量开票') }}</div>
      <div v-for="order in batchOrderList" :key="order.id" class="common-block item">
        <div class="common-t">
          <div class="common-t-l">{{ $t('关联订单发票信息') }}</div>
        </div>
        <div class="info">
          <div v-for="(item,index) in batchOrderField" :key="index" class="li">
            <div class="name">{{ item.name }}：</div>
            <div class="value">{{ order?.invoice[item.key]||''}}</div>
          </div>
          <van-checkbox :value="selectIds.includes(order.id)" shape="square" class="check-box" @click="onChange(order)"></van-checkbox>
        </div>
      </div>
    </div>
    <div v-if="taskStatus==='4004003'" class="common-block item">
      <div class="common-t">
        <div class="common-t-l">{{ $t('开票结果') }}</div>
      </div>
      <div class="info">
        <div v-for="(item,index) in invoiceResult" :key="index" class="li">
          <div class="name">{{ item.name }}：</div>
          <div class="value">{{ billInfo[item.key]||''}}</div>
        </div>
      </div>
    </div>
    <van-calendar v-model="showSelectTime" :first-day-of-week="1" :title="timeKey === 'invoiceArriveDate' ? $t('发票到店时间') : $t('开票日期')" :show-confirm="false" :min-date="minDate" :max-date="maxDate"  @confirm="dateConfirm"/>
    <!-- 二次确认弹窗 -->
    <van-dialog v-model="isDoubleCheck" title="上传确认" show-cancel-button :cancel-button-text="$t('返回')" :confirm-button-text="$t('确认上传')" @confirm="onConfirm(2)">
      <div class="double-check-info">
        <div class="line">{{ $t('发票号码')  }}: {{ this.invoiceNo }}</div>
        <div class="line">{{ $t('开票日期') }}: {{ this.invoiceDate }}</div>
        <div class="line">{{ $t('发票到店时间') }}: {{ this.invoiceArriveDate }}</div>
        <div class="line">{{ $t('车辆识别代号') }}: {{ this.billInfo.vin }}</div>
        <div class="tips">{{ $t('请注意！确认发票与订单车架号相符，并且号码与日期填写正确，发票上传后将无法修改！') }}</div>
      </div>
    </van-dialog>
    <div v-if="taskStatus!=='4004003'" class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
      <!-- 申请开票 -->
      <van-button v-if="billInfo.invoiceStatus==='202410'&&taskStatus!=='4004004'" class="btn submit" type="submit" @click="onSubmit(1)">{{$t('申请开票')}}</van-button>
      <!-- 上传发票 -->
      <van-button v-if="billInfo.invoiceStatus==='202402'" class="btn submit" type="submit" @click="onSubmit(2)">{{ $t('发票上传')}}</van-button>
    </div>
  </div>
</template>
<style lang="less" scoped>
.page{
  padding: 16px;
  padding-bottom: 96px;
}
.item{
  padding:0 12px 12px;
  color: @black;
  // .common-t-l{
  //   &.required::after{
  //     content: '*';
  //     color: #EED484;
  //   }
  // }
  &:not(:last-child){
    margin-bottom: 16px;
  }
  // .label{
  //   margin-bottom: 8px;
  //   display: flex;
  //   align-items: center;
  // }
}
.info{
  position: relative;
  padding-top:8px;
  font-size: 14px;
  .check-box{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .li{
    display: flex;
    margin-bottom: 8px;
    .name{
      color: #929798;
      flex-shrink:0;
    }
    .value{
      word-break: break-all;      
    }
  }
}
/deep/.van-field__control--custom{
  flex-direction: column;
  align-items: flex-start;
}

.double-check-info{
  padding: 12px;
  font-size: 14px;
  .line{
    line-height: 22px;
    word-wrap: break-word;
  }
  .tips{
    color: red;
    margin-top: 12px;
  }
}
</style>
